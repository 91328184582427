import React, { useEffect, useState }  from 'react'
import { useDispatch } from 'react-redux';
import './style/navbar.css'
import { Link as ScrollLink } from 'react-scroll';
import navlogo from '/webapps/gosmart_landingpage/src/assets/Go Smart-02.png';
import toggleicon from '/webapps/gosmart_landingpage/src/assets/Frame 108.png'
import { BrowserRouter as Router, Route, Switch , Routes } from 'react-router-dom';
import ContactUS from './contactus';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons'



export default function Navbaar() {
  
  const location = useLocation();
  const isContactUsPage = location.pathname === '/contactus';
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  

  

  const handleCollapse = () => {
    console.log("handleCollapse");
    var nav = document.getElementById("navbarCollapse");
    var btn = document.getElementById("navbarBtn");
    nav.classList.remove("show");
    btn.classList.add("collapsed");
  };


return (

<nav id="navbar" class="navbar  navbar-expand-xl  navbar-light  fixed-top" >
    <div class="container-fluid">
      <a href="/" class="navbar-brand ">
      <img src={navlogo} alt=""/>

      </a>


      <button style={{border: "0px !important;"}}  class="navbar-toggler" data-toggle="collapse"
        data-bs-target=".navbar-collapse"
        data-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
        onClick={toggleMenu} >
        <img src={toggleicon} alt=""/>
      </button>
     

      


      <div className={`collapse navbar-collapse ${isMenuOpen ? 'show' : ''}`} id="navbarNav">  
         <ul class="navbar-nav navlogo ml-auto">

          
          <li class="nav-item" >
            <a href='' style={{textDecoration:"none"}}>
          <ScrollLink  onClick={toggleMenu} 
            to="banner"  
            smooth={true} 
            duration={500} 
          >
            
            <Link class="nav-link active" to="/">HOME</Link>
            </ScrollLink>
            </a>
          </li>
          <li class="nav-item" >
          <a href='' style={{textDecoration:"none"}}>
          <ScrollLink class="nav-link active"onClick={toggleMenu}
            to="footer"  
            smooth={true} 
            duration={500} 
          >
            ABOUT US
            </ScrollLink>
            </a>
          </li>
          {
            isContactUsPage ? null :
            <li class="nav-item">
            <Link onClick={toggleMenu}  class="nav-link active" to="/contactus">CONTACT US</Link>
            </li>
            }
                    

          {
            isContactUsPage ? null :

            <li class="nav-item">
            <a href='' style={{textDecoration:"none"}}>
            <ScrollLink class="nav-link active"onClick={toggleMenu}
              to="application"  
              smooth={true} 
              duration={400} 
            >
              APPLICATIONS              
  
              </ScrollLink>
              </a>
            </li>

          }

            {
              isContactUsPage ? null :

              <li class="nav-item">
          <a href='' style={{textDecoration:"none"}}>
          <ScrollLink class="nav-link active"onClick={toggleMenu}
            to="techscroll"  
            smooth={true} 
            duration={500} 
          >
            TECHNOLOGIES

            </ScrollLink>
            </a>
          </li>
            }
          
          {
          isContactUsPage ? null :
          <li class="nav-item">
          <a href='' style={{textDecoration:"none"}}>
          <ScrollLink class="nav-link active"onClick={toggleMenu}
            to="ourprojects"  
            smooth={true} 
            duration={500} 
          >
            OUR PROJECTS

            </ScrollLink>
            </a>
          </li>
}
            {/* <li class="nav-item">
            <a href="" style={{textDecoration:"none"}}>
            <Link class="nav-link active" to="/signin">
            <FontAwesomeIcon icon={faUser} style={{fontWeight: 'lighter',color: "#0175ff",paddingRight:"5px"}} />  
             SIGN IN</Link> 




            </a>
          </li> */}
        </ul>


      
      </div>



    </div>


  </nav>


 


  // {/* <Route path="/contact" element={<ContactUS />} /> */}









);




}